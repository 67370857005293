import * as React from 'react';
import {PositionComponent} from '../_PositionComponent';

export default () => <PositionComponent
    showOverview={false}
    title={'Senior Software Engineer (C++ 14) - Self-driving cars'}
    aboutPositionTitle={'The Platform'}
    aboutPosition={`
       We're building a precise location service, specifically targeting the self-driving vehicle market.
       Precision localization for Autonomous Vehicles (AVs) is a critical problem that remains unsolved and
       is quickly becoming a roadblock to general deployment of AVs at scale and increasing the safety of
       existing highly automated vehicles. Our unique solution easily plugs into existing AV architectures
       and achieves an accuracy of a few centimeters with costs a fraction of competing techniques,
       all using existing automotive hardware found on L2 vehicles.
    `}
    aboutRole={`
        We are looking for a bright engineer with a vision who likes to make things happen to help us build the future of self-driving cars.
        <br/><br/>
        You will have the opportunity to work with Domain experts with over 5 decades of applied precision localization
        experience to revolutionize the way self-driving cars detect and understand their position in 3D space to within 1 centimeter.
        <br/>
        You will be part of our RnD team, working closely with Computer Vision and Engineering Teams based in San Francisco.
    `}
    blocks={[
        { title: 'What you’ll work on',
            list: [
                'Solving computer vision focused problems in real time on board a vehicle',
                'Implementing computer vision algorithms capable to run on a low-cost automotive-grade hardware in close collaboration with the domain experts from the Computer Vision team based in SF',
                'Brainstorming the architecture, thinking through the best ways to solve complex technical challenges',
                'Implementing real time algorithms running on low power hardware',
            ]
        },
        { title: 'What You Have',
            list: [
                'Strong CS background',
                'Vast experience with large C++ projects',
                'Experience with real time algorithm implementation',
                'Advanced English',
            ]
        },
        { title: 'Would be a plus',
            list: [
                'Masters or PhD in Computer Science',
                'Experience with C++ 11/14',
                'Experience with Eigen, Sophus, Bazel ',
                'Experience in computer vision algorithms',
            ]
        },
    ]}
/>;
